
.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;

  -moz-transition-duration: 0.3s !important;
    -o-transition-duration: 0.3s !important;
-webkit-transition-duration: 0.3s !important;
       transition-duration: 0.3s !important;

}

.react-tagsinput--focused {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-tagsinput--disabled{
  background-color: #e9ecef;
}

.react-tagsinput-tag {
  background-color: #59abcb;
  border-radius: 0.25rem;
  border: 1px solid #59abcb;
  color: white;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5.5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " × ";
  color: white;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 0px;
  outline: none;
  padding: 4px;
  width: 120px;
}

.inline-block{
  display: inline-block;
}
