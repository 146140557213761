body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif !important;
}

.jumbotron {
  background: rgb(246, 246, 246);
  border-radius: 15px;
}

.modal-xl {
  max-width: 900px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@media (min-width: 400px) {
  .modal-xl {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media (min-width: 800px) {
  .modal-xl {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media (min-width: 850px) {
  .modal-xl {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
}

@media (min-width: 900px) {
  .modal-xl {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (min-width: 992px) {
  .modal-xl {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.greenBg {
  background-color: #59abcb !important;
}

@media (max-width: 575px) {
  .jumbotron {
    background: white;
    border-radius: 0;
  }

  .categoryTitle {
    margin-left: 20px !important;
    font-size: 30px !important;
  }
}

.selectable,
.selectable p,
.selectable h1,
.selectable h2,
.selectable a {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.alert-shown {
  opacity: 1;
  transition: all 250ms;
  top: 0px;
}

.alert-hidden {
  opacity: 0;
  top: -66px;
  transition: all 250ms;
}

.segmented-control {
  -moz-transition-duration: 0.3s !important;
  -o-transition-duration: 0.3s !important;
  -webkit-transition-duration: 0.3s !important;
  transition-duration: 0.3s !important;
}

.hover-container .hover-container--on,
.hover-container:hover .hover-container--off {
  opacity: 0;
}

.hover-container:hover .hover-container--on {
  opacity: 1;
}

.ring-icon:hover {
  opacity: 0.5 !important;
}

#opacityonhover:hover {
  opacity: 0.7 !important;
}

#opacityonhover {
  opacity: 1.0;
}

.btn-primary {
  background-color: #59abcb;
  border-color: #59abcb;
}

.btn-primary:hover {
  background-color: #0084c4;
  border-color: #0084c4;
}

.btn-primary:active {
  background-color: #59abcb;
  border-color: #59abcb;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #59abcb;
  border-color: #59abcb;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #59abcb;
  border-color: #59abcb;
}

a {
  color: #007486;
}

a:hover {
  color: #01295f;
}

/*MOVE TO SEPERATE FILE*/
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin-left: -5px;
  padding: 0;
  list-style-type: none;
  z-index: 2;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.navbar {
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.react-tagsinput-input {
  width: 150px !important;
}

@media (max-width: 400px) {
  .shrink-400 {
    font-size: 14px;
    padding-right: 0.3rem !important;
  }
}

@media (max-width: 350px) {
  .shrink-320 {
    font-size: 15px;
    padding-right: 0.3rem !important;
  }
}

@media (max-width: 330px) {
  .flexible-font {
    font-size: 0.8rem;
  }
}

@supports (padding-top: constant(safe-area-inset-top)) {
  .navbar {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-left: constant(safe-area-inset-left);
    --safe-area-inset-right: constant(safe-area-inset-right);
    padding-top: calc(0.5rem + var(--safe-area-inset-top));
    padding-left: calc(1rem + var(--safe-area-inset-left));
    padding-right: calc(1rem + var(--safe-area-inset-right));
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  .navbar {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
    padding-top: calc(0.5rem + var(--safe-area-inset-top));
    padding-left: calc(1rem + var(--safe-area-inset-left));
    padding-right: calc(1rem + var(--safe-area-inset-right));
  }
}

.modal-dialog {
  margin-top: 0px;
  margin-bottom: 100px;
}

.saContainer {
  margin-top: 105px;
}

.saContainer.notDesktop {
  margin-top: 80px
}

.saContainer.isSmallDevice {
  margin-top: 105px;
}


.modal {
  margin-top: 0px
}

@supports (margin-top: constant(safe-area-inset-top)) {
  .saContainer {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-left: constant(safe-area-inset-left);
    --safe-area-inset-right: constant(safe-area-inset-right);
    padding-left: calc(15px + var(--safe-area-inset-left));
    padding-right: calc(15px + var(--safe-area-inset-right));
    margin-top: calc(105px + var(--safe-area-inset-top));
  }

  .saContainer.notDesktop {
    --safe-area-inset-top: constant(safe-area-inset-top);
    margin-top: calc(80px + var(--safe-area-inset-top));
  }

  .saContainer.isSmallDevice {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: calc(105px + var(--safe-area-inset-top));
  }


  .modal-dialog {
    --safe-area-inset-top: constant(safe-area-inset-top);
    margin-top: calc(62px + var(--safe-area-inset-top));
  }
}

@supports (margin-top: env(safe-area-inset-top)) {
  .saContainer {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
    padding-left: calc(15px + var(--safe-area-inset-left));
    padding-right: calc(15px + var(--safe-area-inset-right));
    margin-top: calc(105px + var(--safe-area-inset-top));
  }

  .saContainer.notDesktop {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: calc(80px + var(--safe-area-inset-top));
  }

  .saContainer.isSmallDevice {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: calc(105px + var(--safe-area-inset-top));
  }




  .modal-dialog {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: calc(62px + var(--safe-area-inset-top));
  }
}

.loading {
  width: 20%;
}

.react-datepicker__input-container {
  margin-top: -8px !important;
}

.reportContainer {
  position: 'absolute' !important;
  top: calc(50% - 240px);
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
}

@media (max-height: 530px) {
  .reportContainer {
    top: 20px;
  }
}

@media (max-width: 768px) {
  .reportContainer {
    top: 40px;
  }
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper>div {
  position: absolute;
}

.detail-page {
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
  height: 100vh;
  background-color: #03a9f4;
  transition: transform 0.5s, opacity 0.5s;

  &.transition-appear {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  &.transition-appear.transition-appear-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.transition-leave {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.transition-leave.transition-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.back-link:hover {
  opacity: 0.75;
}

.absenceContainer {
  width: 50%;
}

@media (max-width: 990px) {
  .absenceContainer {
    width: 100%;
  }
}

.react-datepicker__input-container {
  width: calc(100% - 8px);
}

.groupSelect,
.officeSelect {
  font-size: 18px !important;
  font-weight: 600;
  color: #59abcb !important;
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: 0.2s;
}

.groupSelect:focus,
.officeSelect:focus,
.search:focus {
  outline: none !important;
  box-shadow: none !important;
  background: none !important;
}

.search::placeholder {
  color: #8E93A2;
}

.groupSelect:hover,
.officeSelect:hover {
  filter: brightness(75%);
}

.groupSelect {
  margin-left: 10px;
  float: left !important;
}

@media (min-width: 580px) {
  .memberModalOfficeSelect {
    margin-left: 258px !important;
  }
}

@media (max-width: 322px) {

  .groupSelect,
  .officeSelect {
    font-size: 10px !important;
    width: 120px !important;
  }

  .categoryTitle {
    font-size: 26px !important;
  }

  .groupSelect {
    margin-left: calc(50% - 120px) !important;
    float: left !important;
  }
}

.navbarImg {
  opacity: 0.7;
  height: 24px;
  margin-bottom: 8px;
  margin-top: -3px;
}

.active .navbarImg {
  filter: brightness(100%);
  opacity: 1.0;
}

.active .sidebarImg {
  opacity: 1 !important;
  filter: sepia(100%) saturate(100) brightness(51%) hue-rotate(120deg) !important;
}

.active .navbarImg {}

.navbar-link:hover img {
  opacity: 0.8;
}

.navbar-link {
  padding-bottom: 0;
  font-size: 13px;
  text-align: center;
  margin-right: 12px;
}

.navbar-link.logout {
  margin-left: 14px;
}

@media (min-width: 700px) {
  .navbar-link.logout {
    margin-left: 22px;
  }
}

@media (min-width: 750px) {
  .navbar-link {
    margin-right: 17px;
  }
}

@media (min-width: 850px) {
  .navbar-link {
    margin-right: 19px;
  }

  .navbar-link.logout {
    margin-left: 34px;
  }
}

@media (max-width: 575px) {
  .navbar-link.logout {
    margin-top: 25px;
    margin-left: -2px;
  }
}

.cls-1 {
  fill: none;
  stroke: #fff;
  strokeWidth: 25px;
}

.with-indicator {
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  border-radius: 0px 8px 8px 0px;
}

.sidebarItems .nav-link:nth-child(1).active~.with-indicator {
  top: 89px !important;
}

.sidebarItems .nav-link:nth-child(2).active~.with-indicator {
  top: calc(89px + 60px) !important;
}

.sidebarItems .nav-link:nth-child(3).active~.with-indicator {
  top: calc(89px + 60px + 60px) !important;
}

.sidebarItems .nav-link:nth-child(4).active~.with-indicator {
  top: calc(89px + 60px + 60px + 64px) !important;
}

.sidebarItems .nav-link:nth-child(5).active~.with-indicator {
  top: calc(89px + 60px + 60px + 64px + 64px) !important;
}

.sidebarItems .nav-link:nth-child(6).active~.with-indicator {
  top: calc(89px + 60px + 60px + 64px + 64px + 64px) !important;
}

.reportsCard {
  border-radius: 34px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.reportsCard:hover {
  box-shadow: rgba(0, 0, 0, 0.30) 0px 10px 25px 0px;
}

.control-label {
  text-align: left !important;
}

.modal-content {
  border: none !important;
  padding: 15px;
  border-radius: 30px;
}

.giveMeEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  /* fallback */
  max-height: 48px;
  /* fallback */
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.searchSuggestion:hover {
  background-color: #59abcb;
  color: white !important;
}

.searchSuggestion:nth-of-type(1) {
  border-top: none !important;
}

.list-group-item span {
  pointer-events: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.endDate,
.startDate {
  font-size: 20px;
  font-weight: 500;
  height: 66px;
  margin-top: 2px;
  text-align: center;
}

.startDate {
  border-radius: 0.25rem 0 0 0.25rem;
}

.endDate {
  border-radius: 0 0.25rem 0.25rem 0;
}

.end-date-container,
.comments-container {
  width: 50%;
}

.comments-container,
.end-date-container {
  float: left;
}

.end-date-container {
  padding-left: 14px;
}

@media (max-width: 990px) {

  .end-date-container,
  .comments-container {
    width: 100%;
  }

  .end-date-container {
    padding-left: 0;
  }

  .endDate,
  .startDate {
    font-size: 1rem;
    font-weight: 400;
    height: calc(2.25rem + 2px);
  }
}

.hovertrigger {
  opacity: 0;
}

.hovertrigger:hover {
  opacity: 0.2;
}

@media (min-width: 1635px) {
  .col-sm-12.col-md-10.offset-md-1 {
    width: 1300px !important;
    display: block;
    flex: none;
    margin-left: calc(50% - 650px);
  }
}

.addPicture:hover {
  opacity: 1.0 !important;
}

/* .workplaceTable col:nth-child(2n+2) {
  background: #EEE
} */

.workplaceTable tr:nth-child(2n+3) {
  background: #F9F9F9
}

.workplaceTable tr {
  border-radius: 8px;
}