/* Styling for input/button */
.react-datepicker-popper {
    z-index: 20;
}

.react-datepicker {
    font-family: "Roboto", serif !important;
    box-shadow: 0 0 20px hsl(0deg 0% 39% / 25%);
    border: unset;
    padding: 20px;
    border-radius: 12px;
}

.datePickerCustomStyling>.react-datepicker__input-container {
    margin: 0 !important;
    background-color: white;
    border-radius: 12px;
    width: 100%;
    height: 40px;
}

.noOverlap>.react-datepicker__input-container {
    height: 20px;
}



.datePickerCustomStyling>.react-datepicker__input-container>input {
    outline: none;
    border: 0;
    height: 40px;
    width: 100%;
    text-align: right;
    padding: 0 12px;
    border-radius: 12px;
    cursor: pointer;
    user-select: none;
}

.datePickerCustomStyling>.react-datepicker__input-container>input:hover {
    background-color: #edf6fa;
    opacity: 0.5;
}

.datePickerCustomStyling>.react-datepicker__input-container>input:focus {
    background-color: #edf6fa;
    opacity: 0.5;
}

.datePickerCustomStyling>.react-datepicker__input-container>.react-datepicker__close-icon::after {
    background-color: #3333331A;
    color: #828282;
    font-weight: 600;
}

.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__header-prev,
.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__header-next {
    top: 3px;
}

.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__day-names,
.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__week {
    display: flex;
    align-items: center;
    justify-content: center;
}

.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__day-name {
    width: 1.7rem;
}

.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__day,
.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__time-name {
    display: flex;
    justify-content: center;
    align-items: center;
}

.datePickerCustomStyling+.react-datepicker__tab-loop .react-datepicker__day--outside-month {
    color: gray;
    background-color: white !important;
}

.react-datepicker__tab-loop {
    margin-top: 0 !important;
}

.react-datepicker__current-month {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 19px;
}

.react-datepicker__header {
    padding-top: 0;
    background-color: unset;
    border-bottom: unset;
}

.react-datepicker__navigation {
    top: 29px;
}

.react-datepicker__navigation--next {
    right: 20px;
}

.react-datepicker__navigation--previous {
    left: 20px;
}

.react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    height: 12px;
    width: 12px;
    border-color: #75b7d8 !important;
}

.react-datepicker__day-name {
    height: 27px;
}

.react-datepicker__day {
    font-family: "Roboto", serif;
    font-size: 15px;
    margin-right: 0;
    margin-left: -1px;
    border-radius: 50%;
    height: 32px;
    width: 32px;
}

.react-datepicker__day:hover {
    border-radius: 50%;
}

.react-datepicker__day--selected {
    background-color: #80bddb;
}

.react-datepicker__day--in-range:nth-last-child(1),
.react-datepicker__day--in-selecting-range:nth-last-child(1),
.react-datepicker__day--in-range:nth-last-child(1):hover,
.react-datepicker__day--in-selecting-range:nth-last-child(1):hover {
    border-radius: 0 25% 25% 0 !important;
}

.react-datepicker__day--in-range:nth-child(1),
.react-datepicker__day--in-selecting-range:nth-child(1),
.react-datepicker__day--in-range:nth-child(1):hover,
.react-datepicker__day--in-selecting-range:nth-child(1):hover {
    border-radius: 25% 0 0 25% !important;
}

.react-datepicker__day--selecting-range-start {
    border-radius: 50% 0 0 50% !important;
}

.react-datepicker__day--range-start:hover {
    border-radius: 50% 0 0 50% !important;
}

.react-datepicker__day--in-selecting-range:hover {
    border-radius: 0 50% 50% 0;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
    border-radius: 50%;
}

.react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background-color: #7ebfe0 !important;
    color: #fff;
}

.react-datepicker__day--in-range {
    background-color: #80bddb;
    color: #fff;
    border-radius: 0;
}

.react-datepicker__day--in-range:hover {
    border-radius: 0;
    background-color: #75b7d8;
}

.react-datepicker__day:hover {
    background-color: #75b7d8;
    color: white;
}

.react-datepicker__day--range-start {
    border-radius: 50% 0 0 50% !important;
}

.react-datepicker__day--range-end {
    border-radius: 0 50% 50% 0 !important;
}

.react-datepicker__day--range-end:hover {
    border-radius: 0 50% 50% 0;
}

.react-datepicker__current-month {
    font-weight: 500;
}

.react-datepicker__day-names {
    margin-bottom: -8px;
    font-weight: 500;
}

.react-datepicker__day-name {
    color: black;
    display: inline-block;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    margin: 0.166rem;
    text-transform: uppercase;
    user-select: none;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day--keyboard-selected {
    background-color: unset;
    color: unset;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--in-range {
    border-radius: 0;
    background-color: #7ebfe0;
    color: white;
}

.react-datepicker__day--disabled {
    color: #ccc;
}